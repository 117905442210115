import * as React from "react"
import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="px-4 py-8 m-auto w-full lg:w-1/2">
          <div className="text-4xl lg:text-6xl mb-4">Alla hjärtans dag</div>
          <div className="text-xl">
            <br />
            
            <p>
              <p class="mb-2">FÖRRÄTT</p>
              <b>Toast Skagen</b>
              <br />
              med räkor, créme fraiche & majonnäs toppat med kräftstjärtar
            </p>
            <p class="mb-8">
              <p class="mb-2">HUVUDRÄTT</p>
              <p>
                <b>Oxfilémedaljonger</b>
                <br />
                med rosépepparsås
                <br />
                <div class="text-base">alternativt</div>
                <p>
                  <b>Vitvinspocherad Hälleflundra</b>
                  <br />
                  med hummersås & kräftstjärtar
                </p>
              </p>
            </p>
            <p class="mb-8">
              <p class="mb-2">DESSERT</p>
              <b>Chokladtårta</b>
              <br />
              serveras med frukt & grädde
            </p>
            <b>409 kr</b>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
